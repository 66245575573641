/* You can add global styles to this file, and also import other style files */
::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
    background-color: #37A94C;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
}

.ant-dropdown-menu {
    max-height: 300px;
    overflow: auto;
}

.text-overflow-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.hidden {
    visibility: hidden;
}

th.ant-table-cell {
    font-weight: 900 !important;
}

tbody tr {
    box-shadow: 0px 2px 1px #f5f5f5;
}

.icon-relative-top {
    position: relative;
    top: 2px;
}

.ant-input-suffix .ant-form-item-feedback-icon {
    margin-left: 0.375rem;
}
.ant-modal-body div:first {
    background-color: red;
    padding-top: 1.5rem;
}
.ml-1 {
    margin-left: 0.375rem;
}