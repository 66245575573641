
// Custom Theming for NG-ZORRO
// For more information: https://ng.ant.design/docs/customize-theme/en
@import "../../../node_modules/ng-zorro-antd/ng-zorro-antd.less";

// Override less variables to here
// View all variables: https://github.com/NG-ZORRO/ng-zorro-antd/blob/master/components/style/themes/default.less

@avatar-bg: #D9D9D9;
@avatar-color: #0A0A0A;
@border-radius-base: 4px;
@divider-orientation-margin: 0;
@font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Inter, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
@font-size-base: 16px;
@font-size-lg: @font-size-base;
@height-base: 48px;
@height-lg: 48px;
@menu-item-padding-horizontal: 2rem;
@modal-header-title-font-size: 20px;
@modal-heading-color: #707070;
@primary-color: #37A94C;
@table-header-bg: #FFFFFF;
@table-header-color: @modal-heading-color;
@table-padding-vertical: 32px;
@input-placeholder-color: #707070;
@input-color: #0A0A0A;
@modal-body-padding: 0;
@modal-footer-padding-vertical: 1.5rem;
@modal-footer-padding-horizontal: 2rem;